export default {
  breakpoints: ["480px", "767px", "1024px", "1200px", "1500px", "2000px"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "Rubik, sans-serif",
    heading: "Rubik, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.3,
    heading: 2,
  },
  colors: {
    text: "#000",
    background: "#c4bfb6",
    primary: `rgb(0, 86, 125)`,
    secondary: `rgb(202, 54, 37)`,
    tertiary: `rgb(202, 54, 37)`,
    dark: `#231f20`,
    light: `rgb(196, 191, 182)`,
    muted: "#fff",
  },
  images: {
    logo: {
      maxHeight: "40px",
    },
  },
  buttons: {
    nav: {
      display: ["inline-block", null, "none"],
      background: "none",
      border: "none",
      color: "muted",
      fontSize: 3,
      width: "40px",
    },
    outlined: {
      background: "transparent",
      color: "text",
      border: "1px solid",
      borderColor: "text",
      textDecoration: "none",
      "&:hover": {
        color: "primary",
        borderColor: "primary",
        transition: "all .5s",
      },
    },

    primary: {
      backgroundColor: "primary",
      color: "white",
      border: "solid 2px",
      padding: ["1rem 1.75rem", "1rem 2rem"],
      borderColor: "primary",
      fontWeight: 500,
      borderRadius: "4px",
      textTransform: "uppercase",
      ":hover": {
        color: "primary",
        backgroundColor: "transparent",
      },
    },
    secondary: {
      backgroundColor: "secondary",
      color: "white",
      textTransform: "uppercase",
      border: "solid 2px",
      borderColor: "secondary",
      fontWeight: 500,
      padding: "1rem 1.75rem",
      borderRadius: "4px",
      fontSize: "1rem",
      ":hover": {
        backgroundColor: "transparent",
        color: "secondary",
      },
    },
    tertiary: {
      backgroundColor: "text",
      color: "white",
      textTransform: "uppercase",
      border: "solid 2px",
      borderColor: "text",
      fontWeight: 500,
      padding: "1rem 1.75rem",
      borderRadius: "4px",
      fontSize: "1rem",
      ":hover": {
        backgroundColor: "#c3beb5",
        borderColor: "#c3beb5",
        color: "text",
      },
    },
    quaternary: {
      backgroundColor: "transparent",
      color: "white",
      textTransform: "uppercase",
      border: "solid 2px",
      borderColor: "white",
      fontWeight: 500,
      padding: "1rem 1.75rem",
      borderRadius: "4px",
      fontSize: "1rem",
      ":hover": {
        backgroundColor: "#c3beb5",
        borderColor: "#c3beb5",
        color: "text",
      },
    },
  },
  text: {
    fontFamily: "body",
    itemName: {
      fontWeight: "heading",
    },
    itemDescription: {
      fontWeight: "body",
      fontSize: 1,
    },
    itemPrice: {
      fontWeight: "heading",
    },
  },
  cards: {
    menuCard: {
      maxWidth: 550,
      margin: "auto",
      bg: "muted",
      padding: 1,
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
    },
  },
  box: {
    cardBox: {
      flex: ["1 1 100%", "0 1 50%", "0 1 33%"],
      paddingX: [0, 1, 2],
      paddingY: 3,
      marginRight: "auto",
    },
  },
  text: {
    menuSection: {
      color: "primary",
    },
  },
  links: {
    nav: {
      textDecoration: "none",
      color: "background",
      fontSize: 3,
      marginX: 3,
      marginY: [2, 0, 0],
      fontWeight: "heading",
      fontFamily: "body",
      textTransform: "capitalize",
      display: "inline-block",
      "&:hover:": {
        color: "primary",
        fontSize: 5,
        transition: "all 5s",
      },
    },
  },
  styles: {
    root: {
      margin: 0,
      a: {
        color: "unset",
      },
    },
    spinner: {
      color: "red",
    },
  },
}
